<template>
  <div class="d-flex flex-column flex-root">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
        id="kt_login"
    >
      <!--begin::Aside-->
      <div
          class="login-aside d-flex flex-column flex-row-auto"
          style="background-color: #9a231d"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-15 pt-15">
          <a href="#" class="text-center mb-10">
            <img
                src="media/source/school-logo.jpg"
                class="max-h-100px"
                alt=""
            />
          </a>
        </div>
        <div
            class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
          class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <div class="pb-13 pt-lg-0 pt-5">
              <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
              >
                欢迎使用新生信息采集及宿舍预选系统
              </h3>
            </div>
            <div class="form-group">
              <label class="font-size-h6 font-weight-bolder text-dark"
              >身份证</label
              >
              <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
              >
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="idNumber"
                    v-model="idNumber"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between mt-n5">
                <label class="font-size-h6 font-weight-bolder text-dark pt-5"
                >密码</label
                >
                <p
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    @click="forgetPwd"
                >忘记密码 ?</p
                >
              </div>
              <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
              >
                <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="password"
                    autocomplete="off"
                />
              </div>
            </div>
            <div class="pb-lg-0 pb-5">
              <!--正常登录-->
              <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                  @click="login"
              >
                登 陆
              </button>
              <!--未到开放时间功能区-->
              <!--              <button-->
              <!--                  ref="kt_login_signin_submit"-->
              <!--                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"-->
              <!--                  @click="loginWait"-->
              <!--              >-->
              <!--                登 陆-->
              <!--              </button>-->
            </div>
            <!-- 未到开放时间时提醒信息 -->
            <el-dialog
                title="温馨提醒"
                :visible.sync="dialogVisible"
                width="30%"
                custom-class="dialogClass"
                center>
              <span>宿舍选择开放时间为<b>2023年07月08日</b>之后，当前还未到达系统开放时间，请耐心等待</span>
              <br/>
              <span><b>当前时间：{{ nowDate }}&nbsp;&nbsp;{{ nowTime }}</b></span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false" style="color: white">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 忘记密码时提醒信息 -->
            <el-dialog
                title="温馨提醒"
                :visible.sync="forgetPasswordDialogVisible"
                width="30%"
                custom-class="dialogClass"
                center>
              <span>请尝试默认账号密码</span>
              <br/>
              <span>默认账号：<b>身份证号</b></span>
              <br/>
              <span>默认密码：<b>gengdan + 身份证号后六位</b></span>
              <br/>
              <span>如已修改密码，请联系老师进行处理！</span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="forgetPasswordDialogVisible = !forgetPasswordDialogVisible"
                           style="color: white">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 登录时提醒信息 -->
            <el-dialog
                title="温馨提醒"
                :visible.sync="firstOpenDialogVisible"
                width="30%"
                custom-class="dialogClass"
                center>
              <span>初次登录请使用默认密码</span>
              <br/>
              <span>默认账号：<b>身份证号</b></span>
              <br/>
              <span>默认密码：<b>gengdan + 身份证号后六位</b></span>
              <br/>
              <span>如已修改密码，请使用修改后的密码尝试，如忘记密码，请点击【忘记密码】</span>
              <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="firstOpenDialogVisible = false"
                           style="color: white">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div
            class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a href="https://zx.letoy.xyz" class="text-primary font-weight-bolder font-size-h5"
          >Letoy工作室</a
          >
          <!--          <a href="https://letoy.site" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
          <!--          >咨询</a-->
          <!--          >-->
          <a href="https://zx.letoy.xyz" class="text-primary ml-10 font-weight-bolder font-size-h5"
          >加入我们</a
          >
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

//自定义 el-dialog 样式，需要设置成全局样式
.dialogClass {
  border-radius: 8px;
  min-width: 330px;
}
</style>

<script>
import Swal from "sweetalert2";

export default {
  name: "login-1",
  computed: {
    backgroundImage() {
      return (
          process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  data() {
    return {
      idNumber: "",
      password: "",
      dialogVisible: false,
      forgetPasswordDialogVisible: false,
      firstOpenDialogVisible: false,
      nowDate: "",    // 当前日期
      nowHours: "",   // 当前小时
      nowTime: "",    // 当前时间
      sysCloseStatus: false // 系统关闭
    };
  },
  mounted() {
    this.firstOpenDialogVisible = true;
    // 页面加载完后显示当前时间
    this.dealWithTime(new Date())
    this.sysClose();
    // 定时刷新时间
    this.timer = setInterval(() => {
      this.dealWithTime(new Date()) // 修改数据date
    }, 500)

    // 登录事件绑定
    window.addEventListener('keydown', this.keyDown)
  },
  methods: {
    login() {
      if (this.sysCloseStatus === true) {
        this.$message({
          message: "系统已关闭，无法登录！",
          type: "error",
        });
        this.$router.replace("/sysClose")
      }else {
        this.$axios({
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          url: "/api/login", //后端请求的路径
          method: "post",
          data: {
            idNumber: this.idNumber,
            password: this.$md5(this.password),
          },
        }).then((response) => {
          let res = response.status;
          if (res === 0) {
            sessionStorage.setItem("systemToken", response.token);
            const firstLogin = response.firstLogin;
            sessionStorage.setItem("firstLogin", firstLogin);
            this.$store.commit("changeFirstLogin", firstLogin);
            this.$store.commit("changeChooseState", response.choose);
            this.$message({
              message: "欢迎登录系统！",
              type: "success",
            });
            if (firstLogin) {
              this.$router.push("/main");
            } else {
              this.$router.push("/dashboard");
            }
            window.scrollTo(0, 0);
          } else {
            this.$message({
              message: "用户名或密码错误！",
              type: "error",
            });
          }
        });
      }
    },
    // 绑定键盘事件
    keyDown(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    loginWait() {
      this.dialogVisible = !this.dialogVisible;
    },
    forgetPwd() {
      this.forgetPasswordDialogVisible = !this.forgetPasswordDialogVisible;
    },
    dealWithTime(data) { // 获取当前时间
      let formatDateTime;
      let Y = data.getFullYear();
      let M = data.getMonth() + 1;
      let D = data.getDate();
      let H = data.getHours();
      this.nowHours = H;
      let Min = data.getMinutes();
      let S = data.getSeconds();
      let W = data.getDay();
      H = H < 10 ? "0" + H : H;
      Min = Min < 10 ? "0" + Min : Min;
      S = S < 10 ? "0" + S : S;
      switch (W) {
        case 0:
          W = "日";
          break;
        case 1:
          W = "一";
          break;
        case 2:
          W = "二";
          break;
        case 3:
          W = "三";
          break;
        case 4:
          W = "四";
          break;
        case 5:
          W = "五";
          break;
        case 6:
          W = "六";
          break;
        default:
          break;
      }
      this.nowDate = Y + "年" + M + "月" + D + "日 ";
      this.nowWeek = "周" + W;
      this.nowTime = H + ":" + Min + ":" + S;
      // formatDateTime = Y + "年" + M + "月" + D + "日 " + " 周" +W + H + ":" + Min + ":" + S;
    },
    sysClose() {
      // 当系统时间为2023年8月15日 18点后，系统关闭
      console.log(this.nowDate);
      console.log(this.nowHours.toFixed(0));
      if (this.nowDate === "2023年8月15日 " && this.nowHours.toFixed(0) >= 18) {
        this.sysCloseStatus = true;
      }else if (this.nowDate === "2023年8月16日 " || this.nowDate === "2023年8月17日 " || this.nowDate === "2023年8月18日 " || this.nowDate === "2023年8月19日 " || this.nowDate === "2023年8月20日 ") {
        this.sysCloseStatus = true;
      }
    }
  },
  destroyed() {
    if (this.timer) {  // 注意在vue实例销毁前，清除我们的定时器
      clearInterval(this.timer);
    }
    // 销毁事件绑定
    window.removeEventListener('keydown', this.keyDown, false);
  }
};
</script>
